import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Brisco Magroff`}</strong>{` is a `}<a parentName="p" {...{
        "href": "/Shifter",
        "title": "Shifter"
      }}>{`Shifter`}</a>{` and `}<a parentName="p" {...{
        "href": "/Boneforger",
        "title": "Boneforger"
      }}>{`Boneforger`}</a>{`. In recent years, Brisco has stopped Shifting, vowing to avoid using `}<a parentName="p" {...{
        "href": "/Shape",
        "title": "Shape"
      }}>{`Shape`}</a>{` entirely to change his form.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      